export enum DeviceType {
  LEGIONELLA = 'legionella',
  COLD_STORAGE = 'coldStorage',
  FEEDBACK_PANEL = 'feedbackPanel',
  CLEANING_VALIDATION = 'cleaningValidation',
  DOOR = 'door',
  WATER = 'water',
  MOTION = 'motion',
  CC = 'cc',
}
