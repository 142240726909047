import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute } from '@tanstack/react-router'
import { redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'

export const Route = createFileRoute('/_dashboard/site/$id/dashboard/')({
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.SITE_INFORMATION)) {
      throw redirect({
        to: '/403',
      })
    }

    if (!isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
