import { DTDeviceType } from '@core/referential/dt-device-type.enum'

export enum DTEventType {
  TOUCH = 'touch',
  TEMPERATURE = 'temperature',
  OBJECT_PRESENT = 'objectPresent',
  WATER_PRESENT = 'waterPresent',
  MOTION = 'motion',
  CONNECTION_STATUS = 'connectionStatus',
  ETHERNET_STATUS = 'ethernetStatus',
  BATTERY_STATUS = 'batteryStatus',
  NETWORK_STATUS = 'networkStatus',
  CELLULAR_STATUS = 'cellularStatus',
  WATER_MOVEMENT = 'waterMovement',
  WATER_MOVEMENT_TEMPERATURE = 'waterMovementTemperature',
  CO2 = 'co2',
  HUMIDITY = 'humidity',
  WATER_FLOW = 'waterFlow',
}

export const CoreData = [
  DTEventType.TOUCH,
  DTEventType.TEMPERATURE,
  DTEventType.OBJECT_PRESENT,
  DTEventType.WATER_PRESENT,
  DTEventType.MOTION,
  DTDeviceType.HUMIDITY,
  DTEventType.WATER_MOVEMENT,
] as Array<string>
export const NonCoreData = [
  DTEventType.CONNECTION_STATUS,
  DTEventType.ETHERNET_STATUS,
  DTEventType.BATTERY_STATUS,
  DTEventType.NETWORK_STATUS,
  DTEventType.CELLULAR_STATUS,
] as Array<string>
