import dayjs from 'dayjs'
import { type DateRange } from 'react-day-picker'

export type DateRangeString = {
  startDate: string
  endDate: string
}

export const dateFormat = 'YYYY-MM-DD'

export function parseDateRange({ startDate, endDate }: { startDate: Date; endDate: Date }): { startDate: string; endDate: string } {
  return {
    startDate: dayjs(startDate).format(dateFormat),
    endDate: dayjs(endDate).format(dateFormat),
  }
}

export function parseDateRangeString({ startDate, endDate }: DateRangeString): DateRange {
  return {
    from: dayjs(startDate).toDate(),
    to: dayjs(endDate).toDate(),
  }
}

export function parseDurationMinutes(durationMinutes: number): { value: number; unit: 'minutes' | 'hours' | 'days' } {
  const duration = dayjs.duration(durationMinutes, 'minutes')
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()

  if (minutes === 0) {
    if (hours === 0) {
      if (days === 0) {
        return { value: 0, unit: 'minutes' }
      }

      return { value: days, unit: 'days' }
    }

    return { value: hours, unit: 'hours' }
  }

  return { value: durationMinutes, unit: 'minutes' }
}

export function getExtremes(startTime: Date, endTime: Date | null) {
  return [dayjs(startTime).subtract(1, 'h').valueOf(), endTime ? dayjs(endTime).valueOf() : dayjs().valueOf()]
}
