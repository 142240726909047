import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute } from '@tanstack/react-router'
import { redirect } from '@tanstack/react-router'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'
import { siteQuery } from '@/queries/site.query'

export const SearchSchema = z.object({
  tab: z.union([z.literal('site'), z.literal('site-workers'), z.literal('installations'), z.literal('works')]).default('site'),
})

export const Route = createFileRoute('/_dashboard/site/$id/information/')({
  validateSearch: SearchSchema,
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.SITE_INFORMATION)) {
      throw redirect({
        to: '/403',
      })
    }

    if (isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  loader({ params, context: { queryClient } }) {
    return queryClient.ensureQueryData(siteQuery(params.id))
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
