import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetByIdSiteSchemaPayload = z.object({
  id: numberOrStringToNumberSchema,
})

const ownerSchema = z.object({
  public: z.boolean(),
  name: z.string().nullable(),
  lastName: z.string().nullable(),
  phone: z.string().nullable(),
  mail: z.string().nullable(),
  address: z.string().nullable(),
  city: z.string().nullable(),
  postcode: z.string().nullable(),
})

const legalRespSchema = z.object({
  type: z.union([z.literal('owner'), z.literal('director'), z.literal('operator')]),
  operator: z.string().nullable(),
  name: z.string().nullable(),
  lastName: z.string().nullable(),
  phone: z.string().nullable(),
  mail: z.string().nullable(),
  address: z.string().nullable(),
  city: z.string().nullable(),
  postcode: z.string().nullable(),
})

const internalUserSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  mail: z.string(),
  team: z.string(),
})

const responsibleSchema = z.object({
  internalTeam: z.object({
    users: z.array(internalUserSchema),
  }),
  legioConsulting: z.object({
    name: z.string(),
    address: z.string(),
    city: z.string(),
    postcode: z.string(),
    users: z.array(internalUserSchema),
  }),
  maintenance: z.object({
    name: z.string(),
    address: z.string(),
    city: z.string(),
    postcode: z.string(),
    users: z.array(internalUserSchema),
  }),
  waterTreatment: z.object({
    name: z.string(),
    address: z.string(),
    city: z.string(),
    postcode: z.string(),
    users: z.array(internalUserSchema),
  }),
  lab1: z.object({
    name: z.string(),
    address: z.string(),
    city: z.string(),
    postcode: z.string(),
    users: z.array(internalUserSchema),
  }),
  lab2: z.object({
    name: z.string(),
    address: z.string(),
    city: z.string(),
    postcode: z.string(),
    users: z.array(internalUserSchema),
  }),
  lab3: z.object({
    name: z.string(),
    address: z.string(),
    city: z.string(),
    postcode: z.string(),
    users: z.array(internalUserSchema),
  }),
})

const installationSchema = z.object({
  general: z.object({
    constructionYear: z.number(),
    lastModifYear: z.number(),
    siteType: z.string(),
    nbOccupant: z.string(),
    riskOccupant: z.string(),
    siteUsage: z.string(),
    riskEquipment: z.string(),
    riskSpecific: z.string(),
  }),
  waterSource: z.object({
    public: z.boolean(),
    privateDetails: z.string(),
  }),
  coldWater: z.object({
    mains: z.string(),
    mainConnector: z.string(),
    column: z.string(),
    connector: z.string(),
    backflow: z.string(),
  }),
  hotWater: z.object({
    mains: z.string(),
    mainConnector: z.string(),
    column: z.string(),
    connector: z.string(),
    usageDetails: z.string(),
  }),
  waterTreatment: z.object({
    softening: z.string(),
    corrosion: z.string(),
    disinfection: z.string(),
  }),
  schematic: z.object({
    uptodate: z.boolean(),
  }),
})

export const GetByIdSiteSchemaResponse = z.object({
  id: numberOrStringToNumberSchema,
  name: z.string(),
  address: z.string(),
  city: z.string(),
  timezone: z.string(),
  postcode: z.string(),
  multiBuilding: z.boolean(),
  owner: ownerSchema.deepPartial().nullable(),
  legalResp: legalRespSchema.deepPartial().nullable(),
  responsible: responsibleSchema.deepPartial().nullable(),
  installation: installationSchema.deepPartial().nullable(),
  buildings: z.array(
    z.object({
      id: numberOrStringToNumberSchema,
      name: z.string(),
      equipmentCount: numberOrStringToNumberSchema.nullable(),
      siteId: numberOrStringToNumberSchema,
    }),
  ),
  photoSiteId: z.string().nullable(),
  files: z
    .object({
      legioSchematic: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioWaterDiag: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioContractLab: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioContractLab2: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioContractLab3: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioContractMaintenance: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioContract: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioContractWaterTreatment: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioWaterSource: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioOtherInstallation: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
      legioOtherAudit: z.array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
          date: z.string(),
        }),
      ),
    })
    .partial(),
})

export type GetByIdSitePayload = z.infer<typeof GetByIdSiteSchemaPayload>
export type GetByIdSiteResponse = z.infer<typeof GetByIdSiteSchemaResponse>
