import { type DeviceFamily } from '@core/referential/device-family.enum'
import { type DTEventType } from '@core/referential/dt-event-type.enum'
import { GetDevicesResponseSchema } from '@shared/schema/device.get.schema'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { type SiteId } from '@/@types/site'
import { apiFetch } from '@/lib/api-fetch'

export function siteDevicesQuery(siteId: SiteId, params: { deviceFamily?: DeviceFamily | null; eventType?: DTEventType }) {
  return queryOptions({
    queryKey: ['site', siteId, 'device', params],
    async queryFn() {
      const searchParams = new URLSearchParams()
      for (const [key, value] of Object.entries(params)) {
        if (value) {
          searchParams.append(key, value)
        }
      }

      const rawResponse = await apiFetch.get(`site/${siteId}/device`, {
        searchParams,
      })

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch site devices ${siteId}`)
      }

      try {
        return await GetDevicesResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse devices response', { cause: error })
      }
    },
  })
}

export type Device = QueryOptionsData<typeof siteDevicesQuery>[number]
