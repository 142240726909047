import { createFileRoute, redirect } from '@tanstack/react-router'

import PendingComponent from '@/components/pending-component'
import { Permission } from '@/lib/permissions'
import { mySitesQuery } from '@/queries/my-sites.query'

export const Route = createFileRoute('/_dashboard/my-sites/')({
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.PATRIMONY_SITES)) {
      throw redirect({
        to: '/403',
      })
    }
  },
  loader({ context }) {
    return context.queryClient.ensureQueryData(mySitesQuery)
  },
  pendingComponent: PendingComponent,
})
