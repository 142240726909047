/* eslint-disable @typescript-eslint/naming-convention */

import { type TranslationShape } from './translation-shape'

export const fr: TranslationShape = {
  zod: {
    timezone: {
      regex: 'Le fuseau horaire est invalide',
    },
    postcode: {
      regex: 'Le code postal est invalide',
    },
    notEmpty: 'Ce champ ne peut pas être vide',
  },
  common: {
    yes: 'Oui',
    no: 'Non',
    noResult: 'Aucun résultat',
    type: 'Type',
    equipment: 'Équipement',
    building: 'Bâtiment',
    floor: 'Étage',
    space: 'Pièce',
    deselect: 'Désélectionner',
    model: 'Modèle',
    name: 'Nom',
    button: {
      add: 'Ajouter',
      cancel: 'Annuler',
      validate: 'Valider',
      save: 'Enregistrer',
      retry: 'Réessayer',
      edit: 'Modifier',
      close: 'Fermer',
      delete: 'Supprimer',
      confirm: 'Confirmer',
    },
    form: {
      timezone: 'Fuseau horaire',
      city: 'Ville',
      postcode: 'Code postal',
      address: 'Adresse',
      lastname: 'Nom',
      firstname: 'Prénom',
      phone: 'Téléphone',
      email: 'Email',
      all: 'Tous',
      all_female: 'Toutes',
      search: 'Rechercher...',
      selectBuilding: 'Sélectionner un bâtiment',
      selectFloor: 'Sélectionner un étage',
      selectSpace: 'Sélectionner une pièce',
    },
    siteName: 'Nom du site',
    site: 'Site',
    users: 'Utilisateurs',
    to: 'au',
    dashboard: 'Tableau de bord',
  },
  badge: {
    group: 'Groupe',
  },
  error: {
    title: 'Oops !',
    description: "Désolé, cette page n'existe pas",
    invalidPostalCode: 'Code postal invalide',
    invalidTimezone: 'Fuseau horaire invalide',
    genericError: 'Oops ! Une erreur est survenue',
    number: 'Nombre invalide',
    devices: "L'alerte doit comporter au moins un équipement",
  },
  login: {
    email: 'Email',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le mot de passe',
    passwordPlaceHolder: '••••••••',
    forgottenPassword: 'Mot de passe oublié',
    rememberMe: 'Se souvenir de moi',
    title: 'Bienvenue sur OuiSense',
    submit: 'Se connecter',
    error: {
      title: 'Erreur',
      badUserBadPassword: 'Mail ou mot de passe invalide',
      userAlreadyAuthenticated: 'Vous êtes déjà connecté',
    },
  },
  mySites: {
    title: 'Mes sites',
    addSite: 'Ajouter un site',
    addYourFirstSite: 'Ajouter votre premier site',
    mySitesError: 'Une erreur est survenue lors du chargement de vos sites',
  },
  site: {
    siteLoadingError: 'Une erreur est survenue lors du chargement des informations du site',
    siteEditError: 'Une erreur est survenue lors de la modification du site',
    uploadPhotoError:
      'Une erreur est survenue lors du téléchargement de votre photo. Veuillez réessayer ultérieurement. Si le problème persiste, contactez-nous',
    uploadPhotoPreviewDialog: {
      title: "Aperçu de l'image",
      previewImageAlt: 'Prévisualisation de la nouvelle image du site',
    },
    tabs: {
      site: 'Informations',
      siteWorkers: 'Intervenants',
      installations: 'Installations',
      works: 'Suivi travaux',
      dailyMonitoring: 'Pilotage quotidien',
      sensors: 'Capteurs',
      myAlerts: 'Mes alertes',
      alertConfiguration: 'Configuration',
      gateways: 'Bornes',
      reports: 'Rapports',
      reportConfiguration: 'Configuration',
    },
    nameAndLocation: {
      title: 'Nom et localisation',
      addPhoto: 'Ajouter une photo',
    },
    owner: {
      title: 'Propriétaire',
      siteIsPublic: 'Le site est public :',
    },
    legalResp: {
      title: 'Responsable des installations',
      description:
        "Le responsable des installations peut être le propriétaire des installations, le directeur de l'ERP ou un exploitant si cette responsabilité lui a été contractuellement déléguée",
      radio: {
        owner: 'Propriétaire des installations',
        director: "Directeur de l'établissement",
        operator: 'Exploitant',
      },
      operator: 'Exploitant',
    },
    buildings: {
      title: 'Bâtiments du site',
      addBuilding: 'Ajouter un bâtiment',
      youCannotDeleteThisBuilding: 'Vous ne pouvez pas supprimer un bâtiment ayant des équipements.',
    },
  },
  equipment: {
    tabs: {
      equipmentWithActions: 'Équipements avec actions préventives',
      samplings: 'Points de prélévements',
      equipmentColdStorage: 'Équipements',
    },
    error: {
      cannotGetEquipment: 'Erreur lors du chargement des équipements',
    },
    groupExplanation: "« Groupe » représente un ensemble d'équipements comme les protections anti-retour/clapet de manière à éviter de les ajouter un par un.",
    individual: 'Équipement individuel',
    category: 'Catégorie',
    withActionsEquipment: 'Équipements avec actions',
    action: 'Action',
    equipmentTypes: {
      airConditioning: 'Climatisation',
      production: 'Production',
      distribution: 'Distribution',
      other: 'Autre',
      usage: 'Usage',
      coldPositive: 'Froid positif',
      coldNegative: 'Froid négatif',
      ambient: 'Ambiance',
    },
    actionCategories: {
      sample: 'Prélèvement',
      temperature: 'Température',
      purge: 'Purge',
      waterTreatment: "Traitement de l'eau",
      maintenance: 'Maintenance',
      humidity: 'Humidité',
    },
    conditions: {
      thMixed: 'TH',
      chlorine: 'Chlore',
      sodium: 'Sodium',
      thSoft: 'TH',
      thRaw: 'TH',
      phosphate: 'Phosphate',
      silicate: 'Silicate',
      conductivity: 'Conductivité',
      ph: 'pH',
      iron: 'Fer',
      th: 'TH',
      tac: 'TAC',
      temperature: 'T',
      redox: 'Redox',
      legionella: 'Lp',
      waterConsumption: 'Conso.',
      pressure: 'P',
      flow: 'Débit',
      deltaPressure: 'delta P',
      microbio36: 'Microorganisme aérobie à 36°C',
      microbio22: 'Microorganisme aérobie à 22°C',
      coli: 'Bactéries coliformes',
      escherichia: 'Escherichia coli',
      enterococci: 'Entérocoques',
      pseudomonas: 'Pseudomonas aeruginosa',
      sulfite: 'Bactéries anaérobies sulfito-réductrices',
      staphy: 'Staphylocoques pathogènes',
    },
    actionNames: {
      thMixedReadingSoftener_manual: 'TH eau brute adoucie',
      chlorineReadingSoftener_manual: 'Chlorures eau brute adoucie (si chloration continue)',
      sodiumReadingSoftener_manual: 'Sodium eau brute adoucie',
      thSoftReadingSoftener_manual: 'TH sortie adoucisseur',
      thRawReadingSoftener_manual: 'TH eau brute',
      filterCleaningSoftener_manual: 'Nettoyage ou remplacement des filtres',
      resinCleaningSoftener_manual: 'Nettoyage et désinfection des résines',
      saltTankCleaningSoftener_manual: 'Nettoyage et désinfection du bac à sel ',
      resinCheckSoftener_manual: "Vérification de l'état des résines",
      saltTankcheckSoftener_manual: 'Vérification du niveau de bac à sel',
      maintenanceSoftenerSoftener_manual:
        'Entretien adoucisseur - Contrôle fonctionnement normal / régénération et boîtier de commande (débit, saumurage, fuite chlorure)',
      softenerCheckSoftener_manual: "Vérification des consommables, fonctionnement de l'adoucisseur, suivi et évaluation du traitement",
      regenerationCheckSoftener_manual: 'Contrôle des cycles de régénération/programmateur',
      settingCheckFilmForming_manual: 'Contrôle et vérification des réglages',
      maintenanceGeneralFilmForming_manual: 'Maintenance générale du groupe de dosage (tubing, clapets, membrane, injection…)',
      pulseEmitterCheckFilmForming_manual: 'Test de fonctionnement de l’émetteur d’impulsions du compteur',
      phosphateReadingHotGeneralFlow_manual: 'Phosphate (P2O5)',
      silicateReadingHotGeneralFlow_manual: 'Silicate (SiO2)',
      conductivityReadingHotGeneralFlow_manual: 'Conductivité',
      phReadingHotGeneralFlow_manual: 'pH',
      ironReadingHotGeneralFlow_manual: 'Taux de fer',
      consumptionCheckFilmForming_manual: 'Vérification de la cohérence (conso. d’eau et produit)',
      corrosionCheckSleevesTest_manual: 'Contrôle de la corrosion des manchettes témoins',
      phReadingColdIncoming_manual: 'pH',
      thReadingColdIncoming_manual: 'TH',
      conductivityReadingColdIncoming_manual: 'Conductivité',
      chlorineReadingColdIncoming_manual: 'Taux de chlore libre',
      tacReadingColdIncoming_manual: 'TAC',
      temperatureReadingColdColdIncoming_auto: 'EF - Température - Automatique',
      temperatureReadingColdColdIncoming_manual: 'EF - Température - Manuel',
      microbio36ReadingColdIncoming_manual: 'Potabilité - Microorganisme aérobie à 36°C (D1)',
      microbio22ReadingColdIncoming_manual: 'Potabilité - Microorganisme aérobie à 22°C (D1)',
      coliReadingColdIncoming_manual: 'Potabilité - Bactéries coliformes (D1)',
      escherichiaReadingColdIncoming_manual: 'Potabilité - Escherichia coli (D1)',
      enterococciReadingColdIncoming_manual: 'Potabilité - Entérocoques (D1)',
      pseudomonasReadingColdIncoming_manual: 'Potabilité - Pseudomonas aeruginosa',
      sulfiteReadingColdIncoming_manual: 'Potabilité - Spores de bactéries anaérobies sulfito-réductrices (D1)',
      chemicalD1ReadingColdIncoming_manual:
        'Potabilité - Analyse chimique D1 (température, aspect, couleur, saveur, odeur, turbidité, pH, conductivité, nitrates, chlore libre et total, fer total, aluminium, ammonium)',
      chemicalD2ReadingColdIncoming_manual:
        'Potabilité - Analyse chimique D2 (plomb, fer total, cadmium, antimoine, chlorites, chrome, cuivre, nickel, nitrites, HAP, trihalométhanes, épichlorhydrine, acrylamide et chlorure de vinyle)',
      settingCheckChlorinationStation_manual: 'Contrôle et vérification des réglages',
      maintenanceGeneralChlorinationStation_manual: 'Maintenance & nettoyage du groupe dosage (tubing, clapets, membrane, injection…)',
      cleaningChlorineSensorChlorinationStation_manual: 'Nettoyage et étalonnage de la sonde chlore',
      pulseEmitterCheckChlorinationStation_manual: 'Test de fonctionnement de l’émetteur d’impulsions du compteur',
      chlorineReadingUseLegionella_manual: 'Taux de chlore libre - Point proche ou éloigné (si chloration continue)',
      chlorineReadingUseIndividualBalneo_manual: 'Taux de chlore libre - Point proche ou éloigné (si chloration continue)',
      chlorineReadingUseLegionellaVulnerable_manual: 'Taux de chlore libre - Point proche ou éloigné (si chloration continue)',
      redoxReadingChlorinationStation_manual: 'Indication de la sonde redox',
      consumptionCheckChlorinationStation_manual: 'Vérification de la cohérence (conso. d’eau et produit)',
      probeCheckProbeProduction_manual: 'Vérification des thermomètres (via référence)',
      cleaningDescalingHotCollectiveTank_manual: 'Nettoyage, détartrage et désinfection ',
      anodeCheckHotCollectiveTank_manual: 'Vérification de l’anode sacrificielle',
      drainHotCollectiveTank_manual: 'Chasse fond de ballon (via vanne de vidange)',
      temperatureReadingHotHotCollectiveTank_auto: 'ECS - Température - Automatique',
      temperatureReadingHotHotCollectiveTank_manual: 'ECS - Température - Manuelle',
      legionellaReading2HotHotCollectiveTank_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotHotCollectiveTank_manual: 'ECS - Legionella pneumophila 1er jet',
      cleaningHeatExchanger_manual: 'Nettoyage, détartrage et désinfection ',
      tightnessCheckHeatExchanger_manual: "Contrôle de l'étanchéité des échangeurs",
      cleaningDescalingColdTank_manual: 'Nettoyage, détartrage et désinfection ',
      cleaningDescalingHotIndividualTank_manual: 'Nettoyage, détartrage et désinfection ',
      anodeCheckHotIndividualTank_manual: 'Vérification de l’anode sacrificielle',
      drainHotIndividualTank_manual: 'Chasse fond de ballon (via vanne de vidange)',
      temperatureReadingHotHotIndividualTank_auto: 'ECS - Température - Automatique',
      temperatureReadingHotHotIndividualTank_manual: 'ECS - Température - Manuelle',
      electricalCheckHotIndividualTank_manual:
        'Ballon électrique : Contrôle serrage connexions électriques, état câblage, coffret de commande et de puissance, anode sacrificielle',
      legionellaReading2HotHotIndividualTank_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotHotIndividualTank_manual: 'ECS - Legionella pneumophila 1er jet',
      deaeratorCheckDeaeratorDistribution_manual: 'Contrôle des dégazeurs automatiques ',
      thermostatCheckThermostat_manual: 'Contrôle des thermostats',
      changeoverCirculationPump_manual: 'Permutation des pompes de boucle',
      pumpCheckCirculationPump_manual: 'Vérification / Maintenance des pompes',
      waterConsumptionReadingWaterMeter_manual: 'Consommation d’eau : Relevé du compteur d’eau froide',
      tightnessCheckNonReturnProtectionProduction_manual: 'Contrôle étanchéité des protections anti-retour (alimentation EF de la prod. ECS et Retour)',
      checkBackflowPreventerBackflowPreventerGroup_manual: 'Contrôle des disconnecteurs',
      checkProtectionNonReturnProtectionDistribution_manual: 'Contrôle des protections anti-retours (clapets) et remplacement si besoin',
      checkBackflowPreventerBackflowPreventer_manual: 'Contrôle des disconnecteurs',
      temperatureReadingHotHotGeneralFlow_auto: 'ECS - Température - Automatique',
      temperatureReadingHotHotGeneralFlow_manual: 'ECS - Température - Manuelle',
      thMixedReadingHotGeneralFlow_manual: 'TH eau brute adoucie',
      pressureReadingHotGeneralFlow_manual: 'Contrôle de la pression sur départ ECS',
      chlorineReadingHotGeneralFlow_manual: 'Taux de chlore libre sur départ ECS (si chloration continue)',
      legionellaReading2HotHotGeneralFlow_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotHotGeneralFlow_manual: 'ECS - Legionella pneumophila 1er jet',
      temperatureReadingHotHotGeneralReturn_auto: 'ECS - Température - Automatique',
      temperatureReadingHotHotGeneralReturn_manual: 'ECS - Température - Manuelle',
      thMixedReadingHotGeneralReturn_manual: 'TH eau brute adoucie',
      legionellaReading2HotHotGeneralReturn_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotHotGeneralReturn_manual: 'ECS - Legionella pneumophila 1er jet',
      chlorineReadingHotGeneralReturn_manual: 'Taux de chlore libre sur retour général ECS (si chloration continue)',
      temperatureReadingHotHotLoopReturn_auto: 'ECS - Température - Automatique',
      temperatureReadingHotHotLoopReturn_manual: 'ECS - Température - Manuelle',
      thMixedReadingHotLoopReturn_manual: 'TH eau brute adoucie',
      flowReadingHotLoopReturn_manual: 'Contrôle du débit retour de boucle ECS',
      legionellaReading2HotHotLoopReturn_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotHotLoopReturn_manual: 'ECS - Legionella pneumophila 1er jet',
      chlorineReadingHotLoopReturn_manual: 'Taux de chlore libre sur retour de boucle ECS (si chloration continue)',
      temperatureReadingHotHotLoopStart_auto: 'ECS - Température - Automatique',
      temperatureReadingHotHotLoopStart_manual: 'ECS - Température - Manuelle',
      thMixedReadingHotLoopStart_manual: 'TH eau brute adoucie',
      flowReadingHotLoopStart_manual: 'Contrôle du débit départ de boucle ECS',
      legionellaReading2HotHotLoopStart_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotHotLoopStart_manual: 'ECS - Legionella pneumophila 1er jet',
      chlorineReadingHotLoopStart_manual: 'Taux de chlore libre sur départ de boucle ECS (si chloration continue)',
      temperatureReadingColdColdGeneralFlow_auto: 'EF - Température - Automatique',
      temperatureReadingColdColdGeneralFlow_manual: 'EF - Température - Manuel',
      pressureReadingColdGeneralFlow_manual: 'Contrôle de la pression mise en distribution EF',
      filterCheckLegionellaFilter_manual: 'Contrôle filtres terminaux et changement cartouches si besoin',
      drainLowPointNetwork_manual: 'Chasse : Purge points bas du réseau (bas de colonne)',
      autoFlushCheckAutoFlush_manual: 'Vérification des chasses automatiques',
      temperatureCheckProbeDistribution_manual: 'Vérification sondes de température (via référence étalonnée)',
      deaeratorCheckDeaeratorProduction_manual: 'Vérification purgeurs automatiques, dégazeurs',
      balanceCheckValveDistribution_manual: 'Vérification / ajustement de l’équilibrage du réseau',
      generalCheckValveDistribution_manual: 'Manœuvre et contrôle du bon fonctionnement',
      insultationCheckInsulation_manual: 'Calorifugeage : Vérification visuel de l’état ',
      heatingCordCheckHeatingCord_manual: 'Vérification cordons chauffants',
      purgeUsePurged_manual: "Purge (écoulement d'eau)",
      filterCheckAirConditioning_manual: 'Contrôle visuel filtres et condensas (écoulement et dépôt)',
      filterCleaningAirConditioning_manual: 'Nettoyage et désinfection des filtres',
      tankCleaningAirConditioning_manual: 'Nettoyage et désinfection du bac',
      surfaceCleaningAirConditioning_manual: 'Nettoyage et désinfection des surfaces ',
      legionellaReading2ColdAirConditioning_manual: 'EF - Legionella pneumophila 2ème jet',
      legionellaReading1ColdAirConditioning_manual: 'EF - Legionella pneumophila 1er jet',
      temperatureReadingHotUseLegionella_auto: 'ECS - Température avant mitigeur - Automatique',
      temperatureReadingHotUseLegionella_manual: 'ECS - Température avant mitigeur - Manuelle',
      temperatureReadingColdUseLegionella_auto: 'EF - Température avant mitigeur - Automatique',
      temperatureReadingColdUseLegionella_manual: 'EF - Température avant mitigeur - Manuel',
      temperatureReadingBlendedUseLegionella_auto: 'Eau Mitigée - Température - Automatique',
      temperatureReadingBlendedUseLegionella_manual: 'Eau Mitigée - Température - Manuelle',
      purgeUseLegionella_auto: 'Purge - Action générée en fonction des remontées capteurs',
      purgeUseLegionella_manual: "Purge (écoulement d'eau)",
      legionellaReading2ColdUseLegionella_manual: 'EF - Legionella pneumophila 2ème jet',
      legionellaReading1ColdUseLegionella_manual: 'EF - Legionella pneumophila 1er jet',
      legionellaReading2HotUseLegionella_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotUseLegionella_manual: 'ECS - Legionella pneumophila 1er jet',
      legionellaReading2BlendedUseLegionella_manual: 'Eau mitigée - Legionella pneumophila 2ème jet',
      legionellaReading1BlendedUseLegionella_manual: 'Eau mitigée - Legionella pneumophila 1er jet',
      descaleShowerFitting_manual: 'Détartrage & désinfection pommes de douche et flexibles (ou remplacement avec désinfection avant pose)',
      descaleTapFitting_manual: 'Détartrage désinfection brise jets et mousseurs (ou remplacement avec désinfection avant pose) ',
      mixerCheckFitting_manual: 'Contrôle du bon fonctionnement mitigeurs et mélangeurs',
      descaleWaterFountainGroup_manual: 'Détartrage et désinfection ',
      microbio36ReadingWaterFountain_manual: 'Potabilité - Microorganisme aérobie à 36°C (D1)',
      microbio22ReadingWaterFountain_manual: 'Potabilité - Microorganisme aérobie à 22°C (D1)',
      coliReadingWaterFountain_manual: 'Potabilité - Bactéries coliformes (D1)',
      escherichiaReadingWaterFountain_manual: 'Potabilité - Escherichia coli (D1)',
      enterococciReadingWaterFountain_manual: 'Potabilité - Entérocoques (D1)',
      pseudomonasReadingWaterFountain_manual: 'Potabilité - Pseudomonas aeruginosa',
      sulfiteReadingWaterFountain_manual: 'Potabilité - Spores de bactéries anaérobies sulfito-réductrices (D1)',
      generalCheckValveUsage_manual: 'Manœuvre et contrôle du bon fonctionnement',
      microbio36ReadingUsePotability_manual: 'Potabilité - Microorganisme aérobie à 36°C (D1)',
      microbio22ReadingUsePotability_manual: 'Potabilité - Microorganisme aérobie à 22°C (D1)',
      coliReadingUsePotability_manual: 'Potabilité - Bactéries coliformes (D1)',
      escherichiaReadingUsePotability_manual: 'Potabilité - Escherichia coli (D1)',
      enterococciReadingUsePotability_manual: 'Potabilité - Entérocoques (D1)',
      pseudomonasReadingUsePotability_manual: 'Potabilité - Pseudomonas aeruginosa',
      sulfiteReadingUsePotability_manual: 'Potabilité - Spores de bactéries anaérobies sulfito-réductrices (D1)',
      enterococciReadingPoolBalneo_manual: 'Piscine - Entérocoques',
      escherichiaReadingPoolBalneo_manual: 'Piscine - Escherichia coli',
      pseudomonasReadingPoolBalneo_manual: 'Piscine - Pseudomonas aeruginosa',
      staphyReadingPoolBalneo_manual: 'Piscine - Staphylocoques pathogènes',
      microbio36ReadingPoolBalneo_manual: 'Piscine - Microorganisme aérobie à 36°C',
      sulfiteReadingPoolBalneo_manual: 'Piscine - Spores de bactéries anaérobies sulfito-réductrices',
      microbio36ReadingUseStdCareWater_manual: 'ESS - Microorganisme aérobie à 36°C',
      microbio22ReadingUseStdCareWater_manual: 'ESS - Microorganisme aérobie à 22°C',
      coliReadingUseStdCareWater_manual: 'ESS - Bactéries coliformes',
      escherichiaReadingUseStdCareWater_manual: 'ESS - Escherichia coli',
      pseudomonasReadingUseStdCareWater_manual: 'ESS - Pseudomonas aeruginosa',
      microbio36ReadingUseBactControlledWater_manual: 'EBC - Microorganisme aérobie à 36°C',
      microbio22ReadingUseBactControlledWater_manual: 'EBC - Microorganisme aérobie à 22°C',
      pseudomonasReadingUseBactControlledWater_manual: 'EBC - Pseudomonas aeruginosa',
      microbioEndoReadingEndoscope_manual: 'Endoscope - Contrôle microbiologique',
      microbioReadingEndoscopeWasherWaterIncome_manual: 'Eau endoscopie - Contrôle microbiologique',
      microbioReadingEndoscopeWasherWaterRinse_manual: 'Eau endoscopie - Contrôle microbiologique',
      microbioReadingDialysisWater_manual: 'Contrôle microbiologique (Microorganisme aérobie, endotoxines)',
      microbioReadingPurifiedWater_manual: 'Contrôle microbiologique (Microorganisme aérobie, endotoxines)',
      changeoverPump_manual: 'Permutation des pompes de boucle',
      pumpCheckPump_manual: 'Vérification / Maintenance des pompes',
      microbio36ReadingUseIndividualBalneo_manual: 'Balneo - Microorganisme aérobie à 36°C',
      microbio22ReadingUseIndividualBalneo_manual: 'Balneo - Microorganisme aérobie à 22°C',
      coliReadingUseIndividualBalneo_manual: 'Balneo - Bactéries coliformes',
      escherichiaReadingUseIndividualBalneo_manual: 'Balneo - Escherichia coli',
      pseudomonasReadingUseIndividualBalneo_manual: 'Balneo - Pseudomonas aeruginosa',
      staphyReadingUseIndividualBalneo_manual: 'Balneo - Staphylocoques pathogènes',
      legionellaReading2ColdUseIndividualBalneo_manual: 'EF - Legionella pneumophila 2ème jet',
      legionellaReading1ColdUseIndividualBalneo_manual: 'EF - Legionella pneumophila 1er jet',
      legionellaReading2HotUseIndividualBalneo_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotUseIndividualBalneo_manual: 'ECS - Legionella pneumophila 1er jet',
      legionellaReading2BlendedUseIndividualBalneo_manual: 'Eau mitigée - Legionella pneumophila 2ème jet',
      legionellaReading1BlendedUseIndividualBalneo_manual: 'Eau mitigée - Legionella pneumophila 1er jet',
      microbio36ReadingBalneoWhirlpool_manual: 'Balneo - Microorganisme aérobie à 36°C',
      microbio22ReadingBalneoWhirlpool_manual: 'Balneo - Microorganisme aérobie à 22°C',
      coliReadingBalneoWhirlpool_manual: 'Balneo - Bactéries coliformes',
      escherichiaReadingBalneoWhirlpool_manual: 'Balneo - Escherichia coli',
      pseudomonasReadingBalneoWhirlpool_manual: 'Balneo - Pseudomonas aeruginosa',
      staphyReadingBalneoWhirlpool_manual: 'Balneo - Staphylocoques pathogènes',
      legionellaReading2ColdBalneoWhirlpool_manual: 'EF - Legionella pneumophila 2ème jet',
      legionellaReading1ColdBalneoWhirlpool_manual: 'EF - Legionella pneumophila 1er jet',
      legionellaReading2HotBalneoWhirlpool_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotBalneoWhirlpool_manual: 'ECS - Legionella pneumophila 1er jet',
      legionellaReading2BlendedBalneoWhirlpool_manual: 'Eau mitigée - Legionella pneumophila 2ème jet',
      legionellaReading1BlendedBalneoWhirlpool_manual: 'Eau mitigée - Legionella pneumophila 1er jet',
      legionellaReading2ColdUseLegionellaVulnerable_manual: 'EF - Legionella pneumophila 2ème jet',
      legionellaReading1ColdUseLegionellaVulnerable_manual: 'EF - Legionella pneumophila 1er jet',
      legionellaReading2HotUseLegionellaVulnerable_manual: 'ECS - Legionella pneumophila 2ème jet',
      legionellaReading1HotUseLegionellaVulnerable_manual: 'ECS - Legionella pneumophila 1er jet',
      legionellaReading2BlendedUseLegionellaVulnerable_manual: 'Eau mitigée - Legionella pneumophila 2ème jet',
      legionellaReading1BlendedUseLegionellaVulnerable_manual: 'Eau mitigée - Legionella pneumophila 1er jet',
      airParticleReadingAirSample_manual:
        "Contrôle particulaire (classification particulaire, classe de cinétique d'élimination des particules, vérification des paramètres aérauliques)",
      airMicrobioReadingAirSample_manual: 'Air - Contrôle microbiologique',
      airSurfaceReadingSurfaceSample_manual: 'Surface - Contrôle microbiologique',
      microbioFoodReadingFoodSample_manual: 'Alimentaire - Contrôle microbiologique',
      microbioFoodSurfaceReadingFoodSurface_manual: 'Surface zone alimentaire - Contrôle microbiologique',
      microbioDishWaterReadingDishWater_manual: 'Eau de plonge - Contrôle microbiologique',
      microbioLinenReadingLinenSample_manual: 'Linge - Contrôle microbiologique',
      microbioMilkReadingBibronnerieMilkSample_manual: 'Lait - Contrôle microbiologique',
      microbioSurfaceBibReadingBibronnerieSurfaceSample_manual: 'Bibronnerie Surface - Contrôle microbiologique',
      temperatureReadingHotHotColumnNonLooped_auto: 'ECS - Température - Automatique',
      thMixedReadingHotColumnNonLooped_manual: 'TH eau brute adoucie',
      temperatureReadingHotHotColumnNonLooped_manual: 'ECS - Température - Manuelle',
      temperatureReadingHotUseIndividualBalneo_auto: 'ECS - Température avant mitigeur - Automatique',
      temperatureReadingHotUseIndividualBalneo_manual: 'ECS - Température avant mitigeur - Manuelle',
      temperatureReadingColdUseIndividualBalneo_auto: 'EF - Température avant mitigeur - Automatique',
      temperatureReadingColdUseIndividualBalneo_manual: 'EF - Température avant mitigeur - Manuelle',
      temperatureReadingBlendedUseIndividualBalneo_auto: 'Eau mitigée - Température - Automatique',
      temperatureReadingBlendedUseIndividualBalneo_manual: 'Eau mitigée - Température - Manuelle',
      purgeUseIndividualBalneo_auto: 'Purge - Action générée en fonction des remontées capteurs',
      purgeUseIndividualBalneo_manual: "Purge (écoulement d'eau)",
      temperatureReadingHotUseLegionellaVulnerable_auto: 'ECS - Température avant mitigeur - Automatique',
      temperatureReadingHotUseLegionellaVulnerable_manual: 'ECS - Température avant mitigeur - Manuelle',
      temperatureReadingColdUseLegionellaVulnerable_auto: 'EF - Température avant mitigeur - Automatique',
      temperatureReadingColdUseLegionellaVulnerable_manual: 'EF - Température avant mitigeur - Manuelle',
      temperatureReadingBlendedUseLegionellaVulnerable_auto: 'Eau mitigée - Température - Automatique',
      temperatureReadingBlendedUseLegionellaVulnerable_manual: 'Eau mitigée - Température - Manuelle',
      purgeUseLegionellaVulnerable_auto: 'Purge - Action générée en fonction des remontées capteurs',
      purgeUseLegionellaVulnerable_manual: "Purge (écoulement d'eau)",
      cleanWaterFountainGroup_manual: 'Désinfection ',
      traceabilityWaterFountainGroup_manual: 'Collecter la fiche de traçabilité',
      cleanWaterFountain_manual: 'Désinfection ',
      descaleWaterFountain_manual: 'Détartrage et désinfection ',
      traceabilityUsePurged_manual: 'Collecter la fiche de traçabilité',
      temperatureReadingHotHeatExchanger_manual: 'ECS - Température - Manuelle',
      temperatureReadingHotHeatExchanger_auto: 'ECS - Température - Automatique',
      rinceBypass_manual: 'Rinçage du bipasse',
      phosphateReadingHotGeneralReturn_manual: 'Phosphate (P2O5)',
      silicateReadingHotGeneralReturn_manual: 'Silicate (SiO2)',
      conductivityReadingHotGeneralReturn_manual: 'Conductivité',
      phReadingHotGeneralReturn_manual: 'pH',
      ironReadingHotGeneralReturn_manual: 'Taux de fer',
      phosphateReadingHotLoopReturn_manual: 'Phosphate (P2O5)',
      silicateReadingHotLoopReturn_manual: 'Silicate (SiO2)',
      conductivityReadingHotLoopReturn_manual: 'Conductivité',
      phReadingHotLoopReturn_manual: 'pH',
      ironReadingHotLoopReturn_manual: 'Taux de fer',
      phosphateReadingHotLoopStart_manual: 'Phosphate (P2O5)',
      silicateReadingHotLoopStart_manual: 'Silicate (SiO2)',
      conductivityReadingHotLoopStart_manual: 'Conductivité',
      phReadingHotLoopStart_manual: 'pH',
      ironReadingHotLoopStart_manual: 'Taux de fer',
      checkFilterWaterFilter_manual: 'Contrôle filtre et encrassement',
      drainHotLoopReturn_manual: 'Chasse sur retour de boucle',
      drainHotLoopStart_manual: 'Chasse sur départ de boucle',
      curveAnalysisCurveTemperature_manual: 'Analyse des courbes de température',
      phReadingColdGeneralFlow_manual: 'pH',
      thReadingColdGeneralFlow_manual: 'TH',
      conductivityReadingColdGeneralFlow_manual: 'Conductivité',
      chlorineReadingColdGeneralFlow_manual: 'Taux de chlore libre',
      tacReadingColdGeneralFlow_manual: 'TAC',
      temperatureReadingColdColdRoomNegative_auto: 'Température - Automatique',
      temperatureReadingColdColdRoomPositive_auto: 'Température - Automatique',
      temperatureReadingColdAmbient_auto: 'Température - Automatique',
      humidityReadingColdColdRoomNegative_auto: 'Humidité - Automatique',
      humidityReadingColdColdRoomPositive_auto: 'Humidité - Automatique',
      humidityReadingColdAmbient_auto: 'Humidité - Automatique',
      samplePdfColdIncoming_manual: 'Prélèvement mixte (PDF)',
      samplePdfHotCollectiveTank_manual: 'Prélèvement mixte (PDF)',
      samplePdfHotIndividualTank_manual: 'Prélèvement mixte (PDF)',
      samplePdfHotGeneralFlow_manual: 'Prélèvement mixte (PDF)',
      samplePdfHotGeneralReturn_manual: 'Prélèvement mixte (PDF)',
      samplePdfHotLoopReturn_manual: 'Prélèvement mixte (PDF)',
      samplePdfAirConditioning_manual: 'Prélèvement mixte (PDF)',
      samplePdfUseLegionella_manual: 'Prélèvement mixte (PDF)',
      samplePdfWaterFountain_manual: 'Prélèvement mixte (PDF)',
      samplePdfUsePotability_manual: 'Prélèvement mixte (PDF)',
      samplePdfPoolBalneo_manual: 'Prélèvement mixte (PDF)',
      samplePdfUseStdCareWater_manual: 'Prélèvement mixte (PDF)',
      samplePdfUseBactControlledWater_manual: 'Prélèvement mixte (PDF)',
      samplePdfEndoscope_manual: 'Prélèvement mixte (PDF)',
      samplePdfEndoscopeWasherWaterIncome_manual: 'Prélèvement mixte (PDF)',
      samplePdfEndoscopeWasherWaterRinse_manual: 'Prélèvement mixte (PDF)',
      samplePdfDialysisWater_manual: 'Prélèvement mixte (PDF)',
      samplePdfPurifiedWater_manual: 'Prélèvement mixte (PDF)',
      samplePdfUseIndividualBalneo_manual: 'Prélèvement mixte (PDF)',
      samplePdfBalneoWhirlpool_manual: 'Prélèvement mixte (PDF)',
      samplePdfUseLegionellaVulnerable_manual: 'Prélèvement mixte (PDF)',
      samplePdfAirSample_manual: 'Prélèvement mixte (PDF)',
      samplePdfSurfaceSample_manual: 'Prélèvement mixte (PDF)',
      samplePdfFoodSample_manual: 'Prélèvement mixte (PDF)',
      samplePdfDishWater_manual: 'Prélèvement mixte (PDF)',
      samplePdfFoodSurface_manual: 'Prélèvement mixte (PDF)',
      samplePdfLinenSample_manual: 'Prélèvement mixte (PDF)',
      samplePdfBibronnerieMilkSample_manual: 'Prélèvement mixte (PDF)',
      samplePdfBibronnerieSurfaceSample_manual: 'Prélèvement mixte (PDF)',
      samplePdfHotLoopStart_manual: 'Prélèvement mixte (PDF)',
    },
    frequencies: {
      daily: '1 jour',
      '3day': '3 jours',
      weekly: '1 semaine',
      fortnightly: '2 semaines',
      monthly: '1 mois',
      '2month': '2 mois',
      '3month': '3 mois',
      '6month': '6 mois',
      yearly: '1 an',
      '2year': '2 ans',
      '5year': '5 ans',
    },
    frequency: 'Fréquence',
    equipmentNames: {
      softener: 'Adoucisseur',
      filmForming: 'Poste Filmogène (anti corrosion / tartre)',
      sleevesTest: 'Manchettes témoin',
      coldIncoming: "Eau d'entrée / Arrivée eau de ville",
      chlorinationStation: 'Poste de désinfection / chloration en continu (chlore)',
      probeProduction: 'Thermomètres/Sondes',
      hotCollectiveTank: 'Ballon collectif ECS',
      heatExchanger: 'Echangeur',
      coldTank: 'Réservoir EF',
      hotIndividualTank: 'Ballon individuel ECS (non bouclé)',
      deaeratorProduction: 'Dégazeur automatique',
      thermostat: 'Thermostat',
      circulationPump: 'Pompe de bouclage',
      waterMeter: "Compteur d'eau",
      nonReturnProtectionProduction: 'Protection anti-retour / clapet',
      backflowPreventerGroup: 'Disconnecteur',
      nonReturnProtectionDistribution: 'Protection anti-retour / clapet',
      backflowPreventer: 'Disconnecteur',
      hotGeneralFlow: 'Départ général ECS',
      hotGeneralReturn: 'Retour général ECS',
      hotLoopReturn: 'Retour de boucle ECS',
      hotLoopStart: 'Départ de boucle ECS',
      coldGeneralFlow: 'Départ distribution EF',
      legionellaFilter: 'Filtres terminaux légionelle',
      lowPointNetwork: 'Points bas du réseau (bas de colonne)',
      autoFlush: 'Chasse automatique',
      probeDistribution: 'Thermomètres/Sondes',
      deaeratorDistribution: 'Dégazeur/Purge automatique',
      valveDistribution: 'Vannes',
      insulation: 'Calorifugeage',
      heatingCord: 'Cordon chauffant',
      usePurged: "Points d'usage / Douches à purger (peu utilisés)",
      airConditioning: 'Climatisation',
      useLegionella: "Point d'usage / Douche standard (hors balneo, vulnérable légio - CLIN)",
      fitting: 'Robinetterie (robinets, douches)',
      waterFountainGroup: 'Fontaines à eau',
      waterFountain: 'Fontaine à eau',
      valveUsage: 'Vannes',
      usePotability: "Point d'usage potabilité",
      poolBalneo: 'Piscine / Bassin balneo',
      useStdCareWater: "Point d'usage - Eau pour Soins Standard (ESS)",
      useBactControlledWater: "Point d'usage - Eau Bactériologiquement Contrôlée (EBC)",
      endoscope: 'Endoscope',
      endoscopeWasherWaterIncome: "Eau d'alimentation pour Laveur Désinfecteur Endoscope (LDE)",
      endoscopeWasherWaterRinse: 'Eau de rinçage terminal pour Laveur Désinfecteur Endoscope (LDE)',
      dialysisWater: "Point du système d'hémodialyse (eau de dialyse)",
      purifiedWater: "Point d'eau purifiée",
      pump: 'Pompe',
      useIndividualBalneo: "Balneo - Point d'usage / douche individuelle",
      balneoWhirlpool: 'Balneo - Bains à remous, douches à jets, jacuzzi, spa',
      useLegionellaVulnerable: "Point d'usage / Douche pour patients vulnérables légio. (CLIN)",
      airSample: "Air d'une Zone à environnement maîtrisée (ZEM)",
      surfaceSample: "Surface d'une Zone à environnement maîtrisée (ZEM)",
      foodSample: 'Aliment (plat)',
      dishWater: 'Eau de plonge',
      foodSurface: 'Surface zone alimentaire',
      linenSample: 'Linge',
      bibronnerieMilkSample: 'Bibronnerie - Lait',
      bibronnerieSurfaceSample: 'Bibronnerie - Surface',
      hotColumnNonLooped: 'Colonne ECS non bouclée',
      bypass: 'Bipasse / Bypass',
      waterFilter: 'Filtre Eau Froide',
      curveTemperature: 'Analyse des courbes de température',
      coldRoomNegative: 'Enceinte Frigorifique Négative',
      coldRoomPositive: 'Enceinte Frigorifique Positive',
      ambient: 'Pièce Enceinte',
    },
    addEquipment: 'Ajouter un équipement',
    location: 'Localisation',
    equipmentName: "Nom de l'équipement",
    editLocation: 'Modifier la localisation',
    editEquipment: "Modifier l'équipement",
    fold: 'Plier tout',
    unfold: 'Déplier tout',
    steps: {
      localisation: {
        description: 'Description et localisation',
        addNewFloor: 'Ajouter un étage',
        addNewSpace: 'Ajouter une pièce',
        equipmentName: "Nom de l'équipement",
        equipmentDescription: "Descriptif de l'équipement",
        equipmentNumber: "Numéro de l'équipement",
        spacePosition: 'Position dans la pièce',
        addActions: 'Ajouter/Modifier des actions à cet équipement',
        addPhoto: 'Ajouter une photo',
      },
      devices: {
        description: 'Actions à réaliser',
      },
    },
  },
  dashboard: {
    topBar: {
      search: {
        placeholder: 'Tous les sites',
        noResult: 'Aucun site ne correspond.',
        cannotFindSites: 'Une erreur est survenue lors du chargement des sites.',
      },
    },
    sidebar: {
      site: 'Site',
      patrimony: 'Patrimoine',
      mySites: 'Mes sites',
      myActions: 'Mes actions',
      users: 'Utilisateurs',
      logout: 'Se déconnecter',
      general: 'Général',
      information: 'Informations',
      equipment: 'Équipements',
      actionsAssignation: 'Assignation des actions',
      actionsDone: 'Actions terminées',
      actions: 'Actions',
      nonConformity: 'Non-conformités',
      monitoring: 'Pilotage',
      remoteMonitoring: 'Télésurveillance',
      alerts: 'Alertes',
      devices: 'Capteurs',
      reports: 'Rapports',
      dashboard: 'Tableau de bord',
    },
  },
  filter: {
    datePicker: {
      placeholder: 'Choisir une date',
      last1days: '24 dernières heures',
      last30days: '30 derniers jours',
      last7days: '7 derniers jours',
      last90days: '3 derniers mois',
      selectRangePlaceholder: 'Choisir une période',
    },
    multiselectLocalisations: {
      expandAll: 'Déplier tout',
      retractAll: 'Plier tout',
      selectAll: 'Sélectionner',
      unSelectAll: 'Désélectionner',
      title: 'Localisation',
    },
    multiselectSubType: {
      title: 'Type de point',
    },
    toggle: {
      ecs: 'ECS',
      ef: 'EF',
    },
  },

  attachments: 'Pièces jointes',
  addFile: 'Ajouter un fichier',
  fileName: 'Nom du fichier',
  fileDate: 'Date du fichier',
  undefinedFloor: 'Étage non précisé',
  undefinedBuilding: 'Bâtiment',
  devicesAndMonitoring: 'Capteurs et surveillance',
  isEquipmentMonitoredByDevice: "Est-ce qu'un capteur surveille cet équipement ?",
  ifYesPleaseSelectTheDevice: 'Si oui, préciser le nom du capteur',
  selectDevice: 'Sélectionner un capteur',
  temperaturePosition: 'Où réalisez-vous le relevé de température ?',
  hotWater: 'Eau chaude',
  coldWater: 'Eau froide',
  mixedWater: 'Eau mitigée',
  thisEquipmentCannotHaveDevice: 'Cet équipement ne peut pas avoir de capteur',
  typeOfWater: "Type d'eau",
  delete: 'Supprimer',
  deletingTheEquipment: 'Suppression de l’équipement',
  youAreAboutToDeleteThisEquipment: "Vous êtes sur le point de supprimer l'équipement : <strong>{{equipmentName}}</strong>",
  deletingTheLocation: 'Suppression de la localisation',
  locationNameAlreadyUsed: 'Ce nom de localisation est déjà utilisé',
  youAreAboutToDeleteThisLocation:
    'Vous êtes sur le point de supprimer la localisation : <strong>{{type}} - {{locationName}}</strong> ainsi que les niveaux en dessous rattachés',
  locationDeletionImposibleBecauseEquipment:
    'Suppression de la localisation impossible car un ou plusieurs équipements sont rattachés à cette localisation ou à un de ses niveaux en dessous',
  noDevicesAvailable:
    "Soit vous n'avez pas de capteur OuiSense soit tous vos capteurs sont déjà assignés à un équipement (modifier vos équipements pour en rendre disponible).",
  next: 'Suivant',
  noFiles: 'Aucun fichier',
  actionsToBeDone: 'Actions à réaliser',
  actionsToSelect: 'Actions à sélectionner',
  todayActions: 'Actions configurées à date',
  actions: 'Actions',
  actionCategories: 'Types d’actions',
  procedure: 'Procédure',
  frequency: 'Fréquence',
  nextSchedule: 'Prochaine échéance',
  activated: 'Activée',
  selected: 'Sélectionnée',
  moreActionsHelpText:
    'Si vous souhaitez ajouter des actions dans la librairie OuiSense pour cet équipement, n’hésitez pas à nous contacter (contact@ouisense.io).',
  conformIfCondition: 'Conforme si',
  notConformIfCondition: 'Non conforme si',
  duringTime: 'pendant',
  duringTimeDay_one: 'pendant {{count}} jour',
  duringTimeDay_other: 'pendant {{count}} jours',
  duringTimeMinute_one: 'pendant {{count}} minute',
  duringTimeMinute_other: 'pendant {{count}} minutes',
  duringTimeHour_one: 'pendant {{count}} heure',
  duringTimeHour_other: 'pendant {{count}} heures',
  value: 'Valeur',
  time: 'Temps',
  minutes_one: 'minute',
  minutes_other: 'minutes',
  hours_one: 'heure',
  hours_other: 'heures',
  days_one: 'jour',
  days_other: 'jours',
  conditionIsInvalid: 'Condition invalide',
  valuesMustBeDifferent: 'Les valeurs doivent être différentes',
  pastDate: 'Date est dépassée',
  procedureForAction: "Procédure pour réaliser l'action",
  procedureIncident: "Procédure en cas d'incident",
  theEquipmentWillNotHavePreventiveActions: "L’équipement n'aura pas d’actions préventives",
  theEquipmentWillNotHaveSamplingActions: "L’équipement n'aura pas de prélèvements",
  invalidDate: 'Date invalide',
  siteEditSuccess: 'Site modifié avec succès',
  siteEditError: 'Erreur lors de la modification du site',
  locationAdded: 'Localisation ajoutée avec succès',
  locationEdited: 'Localisation modifiée avec succès',
  procedureEdited: 'Procédure modifiée avec succès',
  saving: 'Enregistrement en cours',
  equipmentAdded: 'Équipement ajouté avec succès',
  actionsConfigured: 'Actions configurées avec succès',
  addSampling: 'Ajouter un prélèvement',
  samplingType: 'Type de prélèvement (Tous)',
  orAddSampling: 'Ou ajouter un point de prélèvement',
  addUser: 'Ajouter un utilisateur',
  firstname: 'Prénom',
  lastname: 'Nom',
  email: 'Email',
  phone: 'Téléphone',
  resetPassword: 'Mot de passe oublié',
  verificationCode: 'Code de vérification',
  useYourCodeByEmail: 'Utilisez votre code reçu par email',
  resetPasswordSuccess: 'Votre mot de passe a été réinitialisé avec succès',
  backToLogin: 'Retourner à la connexion',
  rateLimitError: 'Trop de tentatives de connexion. Veuillez réessayer dans quelques minutes.',
  cannotDeleteEquipmentWithDevices: 'Vous ne pouvez pas supprimer un équipement ayant un capteur assigné. Vous devez désassigner le capteur au préalable.',
  editUser: 'Modifier un utilisateur',
  nonAvailable: 'Non renseigné',
  selectActionsToAssign: 'Sélectionner les actions à assigner',
  nonAssignedDescription:
    'Les actions sans responsable ("$t(nonAssignedAction)") n\'apparaitront dans aucune liste d\'action (et seront donc par conséquent non réalisées).',
  nonAssignedAction: 'À définir',
  assignSelectedActions: 'Assigner les actions sélectionnées aux responsables',
  responsible: 'Responsable',
  selectResponsible: 'Sélectionner les responsables',
  internal: 'Interne',
  external: 'Externe',
  unset: 'Retirer',
  firstResponsible: '1er responsable',
  firstResponsibleDescription: "seules personnes pouvant voir et valider les actions sur l'application",
  secondResponsible: '2ème responsables',
  secondResponsibleDescription: "personnes pouvant voir et valider les actions qui n'ont pas été réalisé dans les temps par les 1er responsables",
  assign: 'Assigner',
  cannotFetchUsers: 'Impossible de récupérer les utilisateurs',
  warning: 'Attention',
  emptyAssignationWarning:
    "Vous n'avez sélectionné aucun responsable, si vous cliquer sur Assigner, les actions sélectionnées seront mises à jour et plus personne ne verra ces actions",
  noResponsibleAssignationWarning:
    "Vous n'avez sélectionné aucun premier responsable, si vous cliquer sur Assigner , les actions sélectionnées seront mises à jour et plus personne ne verra ces actions dans les temps",
  understand: "J'ai compris",
  actionToDo_one: 'Action à faire : {{count}}',
  actionToDo_other: 'Actions à faire : {{count}}',
  actionLate: 'dont {{count}} en retard',
  actionToDoAt: 'À faire le {{at}}',
  toLate: 'En retard',
  toDo: 'À faire',
  done: 'Fait',
  status: 'Statut',
  noActions: 'Aucune action',
  actionDoneAt: 'Terminée le {{at}}',
  actionStatusDone: 'Actions terminées',
  actionStatusOngoing: 'Actions à faire',
  actionStatusLate: 'Actions en retard',
  actionStatusAll: 'Toutes les actions',
  cannotFetchActionReading: "Impossible de récupérer les informations de l'action",
  action: '<strong>Action</strong> : $t(equipment.actionCategories.{{ category }})',
  condition: 'Condition',
  typeValue: 'Indiquer la valeur',
  noValue: 'Pas de valeur',
  conform: 'Conforme',
  nonConform: 'Pas conforme',
  comment: 'Commentaire',
  incidentToReport: 'Incident à reporter',
  cannotFetchActionReadingFile: "Impossible de récupérer le fichier de l'action",
  equipmentImage: "Image de l'équipement",
  noEquipmentImage: 'Aucune image',
  willReplaceExistingFile: 'Le fichier existant sera remplacé.',
  back: 'Retour',
  preventive: 'Préventive',
  samplings: 'Prélèvement',
  devices: 'Capteurs',
  deletingTheSite: "Suppression d'un site",
  youAreAboutToDeleteThisSite: 'Vous êtes sur le point de supprimer le site : <strong>{{siteName}}</strong>',
  areYouSureYouWantToDeleteThisSite: 'Afin de confirmer la <strong>suppression du site</strong>, veuillez confirmer le nom du site dans le champs ci-dessous',
  warningDeletionSite:
    'La suppresion du site <strong>{{siteName}}</strong> va entraîner la suppression de tous les équipements, localisations et actions associées',
  nameOfTheSiteToRemove: 'Nom du site à supprimer',
  canNotDeleteSiteWithDevices:
    'Suppression du site impossible car un ou plusieurs capteurs sont sont rattachés à ce site, veuillez contacter un administrateur (contact@ouisense.io)',
  atLeastOneBuildingRequired: "Au moins un bâtiment est nécessaire lorsqu'un site détient plusieurs bâtiments.",
  noBuildingAllowed: 'Vous avez spécifié un site sans bâtiment, mais des bâtiments existent pour ce site, veuillez les supprimer au préalable.',
  actionsDoneDetails: 'Détail des actions <u>{{type}}</u> terminées',
  doneAt: 'Fait le',
  notDone: 'Non réalisé',
  details: 'Détail',
  nonApplicable: 'n.a.',
  conformity: 'Conforme',
  nextScheduleAt: 'Échéance le',
  doneStatus: 'Fait dans les temps',
  doneLateStatus: 'Fait en retard',
  notDoneStatus: 'Non réalisé',
  ongoingStatus: 'En cours',
  ongoingLateStatus: 'En retard',
  conformityNotMatchConditionError: `La conformité choisie ainsi que le résulat donné en entrée ne sont pas cohérents`,
  actionsAll: 'Actions (toutes)',
  actionsSelected: 'actions sélectionnées',
  actionSelect: 'Sélectionner une action',
  cannotLoadAddSample: 'Impossible de charger le référentiel',
  cannotLoadSamples: 'Une erreur est survenue au chargement des prélèvements.',
  removeFile: 'Supprimer le fichier',
  removingFile: 'Suppression du fichier',
  removeFileDescription: 'Vous êtes sur le point de supprimer le fichier',
  fileDoesNotExists: "Le fichier n'existe pas",
  fileDeleted: 'Fichier supprimé',
  samplingConformity: 'Conformité des prélèvements',
  samplingDone: 'Prélèvements réalisés',
  temperatureConformity: 'Conformité des relevés de température',
  temperatureDone: 'Relevés de température réalisés',
  purgeDone: 'Purges réalisées',
  actionsConformity: 'Conformité des actions',
  actionsDone: 'Actions réalisées',
  selectFilter: {
    status: '$t(status) ($t(common.form.all))',
    building: '$t(common.building) ($t(common.form.all))',
    floor: '$t(common.floor) ($t(common.form.all))',
    category: '$t(equipment.category) ($t(common.form.all, { "context": "female" }))',
    action: '$t(equipment.action) ($t(common.form.all, { "context" : "female" }))',
    responsible: 'Responsable ($t(common.form.all))',
    toDefinedResponsible: 'Responsable à définir',
    conformity: 'Conformité ($t(common.form.all))',
    type: 'Type ($t(common.form.all))',
    equipment: '$t(common.equipment) ($t(common.form.all))',
    devices: '$t(devices) ($t(common.form.all))',
    users: '$t(common.users) ($t(common.form.all))',
    subType: '$t(subType) ($t(common.form.all))',
    frequency: '$t(frequency) ($t(common.form.all))',
    site: '$t(common.site) ($t(common.form.all))',
    severity: '$t(severity) ($t(common.form.all))',
  },
  atLeastValueOrConformity: 'Au moins une valeur ou une conformité doit être renseignée',
  manual: 'manuelles',
  auto: 'automatiques',
  conformityTooltip: "Pour les points d'usage les relevés de température indiqués sont ceux enregistrés au moment du tirage (équivalent aux relevés manuels)",
  deadline: 'Échéance',
  firstResponsibles: 'Premiers responsables',
  secondaryResponsibles: 'Seconds responsables',
  actionDoneBy: 'Action réalisée par',
  actionDoneWithSensors: 'Action réalisée par capteur',
  start: 'Début',
  end: 'Fin',
  darkGreen: 'vert foncé',
  lightGreen: 'vert pâle',
  orange: 'orange',
  red: 'rouge',
  ecs: 'ECS',
  ef: 'EF',
  months: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
  weekdays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  weekdaysFull: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  resetZoom: 'Réinitialiser le zoom',
  internalSiteWorkers: 'Intervenants internes',
  responsiblesContact: 'Contact des personnes responsables',
  team: 'Équipe',
  addWorker: 'Ajouter un intervenant',
  legioConsulting: "Bureau d'étude sanitaire",
  companyName: "Nom de l'entreprise",
  myContracts: 'Mes contrats',
  wrongFileType: "Le type de fichier n'est pas valide",
  dropFiles: 'Ajouter des fichiers en les déposant ici ou cliquer pour les sélectionner',
  dropFile: 'Ajouter un fichier en le déposant ici ou cliquer pour le sélectionner',
  lab1: "Laboratoire d'analyse",
  lab2: "Second laboratoire d'analyse",
  lab3: "Troisième laboratoire d'analyse",
  maintenance: 'Maintenance',
  waterTreatment: "Traitement de l'eau",
  invalidEmail: 'Adresse email invalide',
  invalidPhone: 'Numéro de téléphone invalide',
  installationGeneralDescription: "Description générale de l'installation",
  constructionYear: "Année de construction des réseaux d'eau",
  lastModifYear: 'Année de dernière modification',
  siteType: 'Type de site',
  occupantNumber: "Nombre d'occupants",
  occupantRisk: 'Occupants à risque',
  siteUsage: 'Utilisation du site',
  riskEquipmentDescription: 'Description des équipements à risque formant des aérosols',
  riskEquipmentDescriptionTooltip: 'Distinguer équipement individuel et collectif',
  riskSpecificDescription: 'Identification de bras morts et / ou niches bactériennes',
  siteWaterSource: 'Alimentation en eau du site',
  publicDistribution: 'Distribution publique',
  privateRessource: 'Ressource privée',
  waterSourcePrivateDetails: 'Si ressource privée indiquer',
  waterSourcePrivateExamples: 'Puits, forage, etc.',
  distributedWaterInfo: "Distribution publique : Fiche d'information annuelle de qualité de l'eau distribuée",
  prefectoralAuthorization: 'Ressource privée : Autorisation préféctorale',
  coldWaterNetwork: "Réseau d'eau froide (EF)",
  coldWaterMains: 'Arrivée et stockage EF',
  coldWaterNetworkEquipments: 'Réseau EF',
  coldWaterNetworkEquipmentsTooltip: 'Indiquer matériaux, calorifugeage (intégral, partiel, inexistant), autres',
  waterMainConnector: 'Collecteurs principaux',
  waterColumn: 'Colonnes',
  waterConnector: 'Piquages',
  coldWaterBackflow: "Dispositifs de protection contre les retours d'eau (clapet, disconnecteur) présents à l'amont de :",
  hotWaterNetwork: "Réseau d'eau chaude sanitaire (ECS)",
  hotWaterMains: 'Production et stockage ECS',
  hotWaterMainsTooltip:
    "Indiquer accumulation / semi instantanée / instantanée, nombre de ballons, capacité, disposition (série / parallèle), avec purge d'air ou non, avec vanne de vidange ou non, avec accès ou non, autres équipements (échangeur)",
  hotWaterNetworkEquipments: 'Réseau ECS',
  hotWaterNetworkEquipmentsTooltip: 'Indiquer matériaux, bouclage (et nombre de boucles), calorifugeage, organe de réglage, cordon chauffant, autres',
  hotWaterUsageDetails: 'Points terminaux ECS',
  hotWaterUsageDetailsTooltip: "Indiquer mitigeur en aval de la production / mitigeur à chaque point d'usage / Autres",
  schematicUpToDate: 'Est-ce que les plans enregistrés sont à jour ?',
  myInstallationSchematics: "Mes plans d'installation",
  installationSchematics: 'Plan des installations',
  otherInstallationFiles: "Autres documents liés à l'installation",
  myInstallationAudits: 'Mes diagnostics / audits',
  myInstallationOtherDocuments: 'Mes autres documents',
  invalidYear: "L'année n'est pas valide",
  incident: 'Incident',
  date: 'Date',
  nonConformity: {
    status: {
      notDone: 'Non vu',
      todo: 'En cours de résolution',
      done: 'Clôturé',
    },
    type: {
      notDone: 'Non réalisé',
      noConformity: 'Non Conforme',
      incident: 'Remontées terrain',
    },
  },
  workSupervision: 'Suivi des travaux',
  workStatus: {
    ongoing: 'En cours',
    todo: 'À faire',
    done: 'Fait',
  },
  works: 'Travaux',
  workSheet: 'Fiche travaux',
  newWorkSheet: 'Nouvelle fiche travaux',
  workDescription: 'Description des travaux',
  workName: 'Libellé',
  planningAndBudget: 'Planning et budget',
  budget: 'Budget',
  responsibleContact: 'Nom et coordonnées des responsables',
  workEditSuccess: 'Travaux modifiés avec succès',
  workEditError: 'Erreur lors de la modification des travaux',
  incidentSheet: "Fiche d'incident",
  incidentDescription: "Description de l'incident",
  incidentStatus: "Statut de l'incident",
  incidentResolution: "Résolution de l'incident",
  noComment: 'Pas de commentaire',
  noIncident: "Pas d'incident",
  noResp: 'Pas de responsable',
  incidentAttachment: "Pièce jointe liée à l'incident",
  incidentEditSuccess: 'Incident édité avec succès',
  incidentEditError: "Erreur lors de l'édition de l'incident",
  incidentResolutionAndActions: "Causes de l'incident et actions menées",
  responsibles: 'Responsables',
  temperatureEvolution: 'Évolution des températures',
  waterFlowEvolution: "Évolution de la vitesse d'eau",
  humidityEvolution: "Évolution de l'humidité",
  noCondition: 'Pas de condition',
  unprocessedAlerts: 'Alertes non traitées',
  totalAlerts: 'alertes au total',
  temperature: 'Température',
  process: 'Traiter',
  device: 'Capteur',
  confirmAlertQuestion: "Confirmez-vous le traitement de l'alerte ?",
  disableAlertPreference: 'Je souhaite désactiver toutes les alertes de ce capteur pendant',
  disableAlert: {
    oneDay: '1 jour',
    threeDays: '3 jours',
    oneWeek: '1 semaine',
    twoWeeks: '2 semaines',
    oneMonth: '1 mois',
  },
  charts: 'Courbes',
  alerts: 'Alertes',
  pleaseSelectDevices: 'Veuillez sélectionner au moins un capteur (maximum 5)',
  actionEditSuccess: 'Action modifiée avec succès',
  actionEditError: "Erreur lors de la modification de l'action",
  devicesSelected: 'capteurs sélectionnés',
  slot: 'Plage',
  addSlot: 'Ajouter une plage',
  editSelectedDays: 'Modifier les jours sélectionnés',
  addAlert: 'Ajouter une alerte',
  alertLabel: {
    temperatureAlert: 'Température',
    waterMovementAlert: 'Mouvement',
    humidityAlert: 'Humidité',
  },
  alertType: {
    temperature: 'Température',
    frequenceCounter: 'Fréquence de comptage',
    clickCounter: "Compteur d'événements",
    waterMovement: "Mouvement d'eau",
    peopleFlow: 'Flux de personnes',
    touch: 'Contact',
    usage: 'Utilisation',
    waterMovementTemperature: "Mouvement d'eau et température",
  },
  alertSeverity: {
    critical: 'Élevée',
    error: 'Moyenne',
    warning: 'Faible',
  },
  notificationsTo: 'Notifications vers',
  alertDisabled: 'Désactivée',
  alertEnabled: 'Activée',
  alertCustom: 'Activée (personnalisée)',
  alertRemoveWarning: "Êtes-vous sûr de vouloir supprimer l'alerte",
  severity: 'Sévérité',
  conditions: 'Conditions',
  greaterThan: 'Supérieure à',
  lessThan: 'Inférieure à',
  and: 'et',
  or: 'ou',
  minutes: 'minutes',
  hours: 'heures',
  days: 'jours',
  supervisedEquipments: 'Équipements à surveiller',
  notifications: 'Notifications',
  sms: 'SMS',
  call: 'Appel',
  noUser: "Pas d'utilisateur",
  never: 'Jamais',
  anytime: 'Tout le temps',
  custom: 'Personnalisé',
  usersSelected: 'utilisateurs sélectionnés',
  alertParamType: {
    temperatureAlert: 'Température',
    frequenceCounterAlert: 'Fréquence de comptage',
    clickCounterAlert: "Compteur d'événements",
    waterMovementAlert: "Mouvement d'eau",
    waterFlowAlert: "Vitesse d'eau",
    humidityAlert: 'Humidité',
  },
  sameAlertMinutes: 'Arrêter les notifications sans retour à la normale pendant',
  alertCreateSuccess: 'Alerte créée avec succès',
  alertCreateError: "Erreur lors de la création de l'alerte",
  deleteAlert: "Suppression de l'alerte",
  alertDeleteSuccess: "L'alerte a été supprimée avec succès",
  alertDeleteError: "Erreur lors de la suppression de l'alerte",
  editAlert: "Modifier l'alerte",
  alertEditSuccess: "L'alerte a été modifiée avec succès",
  alertEditError: "Erreur lors de la modification de l'alerte",
  alertsAll: 'Alertes (toutes)',
  alertsSelected: 'alertes sélectionnées',
  alertsSelect: 'Sélectionner une alerte',
  addComment: 'Ajouter un commentaire',
  send: 'Envoyer',
  toComment: 'Commenter',
  alertAcknowledgeSuccess: 'Alerte traitée avec succès',
  alertAcknowledgeError: "Erreur lors du traitement de l'alerte",
  comments: 'Commentaires',
  assignUserSuccess: 'Responsables assignés avec succès',
  assignUserError: "Une erreur est survenue lors de l'assignation des responsables",
  addCommentSuccess: 'Commentaire ajouté avec succès',
  addCommentError: "Erreur lors de l'ajout du commentaire",
  pagination: {
    displayed: 'Affichés',
    of: 'sur',
    results: 'résultats',
    resultsPerPage: 'Résultats par page',
    page: 'Page',
    goToFirstPage: 'Aller à la première page',
    goToPreviousPage: 'Aller à la page précédente',
    goToNextPage: 'Aller à la page suivante',
    goToLastPage: 'Aller à la dernière page',
  },
  logout: 'Déconnexion',
  logoutMessage: 'Souhaitez-vous vraiment vous déconnecter ?',
  all: {
    masc: 'Tous',
    fem: 'Toutes',
  },
  validateActionSuccess: 'Action validée avec succès',
  validateActionError: "Erreur lors de la validation de l'action",
  waterTreatmentDescription: 'Descriptif des traitements, nom des produits, ECS ou EF',
  softening: 'Adoucissement',
  corrosion: 'Anti corrosion / Filmogène',
  disinfection: 'Désinfection (préciser continue ou discontinue)',
  signal: 'Signal',
  deviceFamily: {
    touch: 'Toucher',
    water: 'Eau',
    proximity: 'Proximité',
    temperature: 'Température',
    airQuality: "Qualité de l'air",
    pir: 'Présence',
    qrCode: 'QR code',
    gateway: 'Borne',
    humidity: 'Humidité',
  },
  description: 'Description',
  battery: 'Batterie',
  lastReading: 'Dernier relevé',
  subType: 'Sous-type',
  deviceSubType: {
    usageHot: "Point d'usage eau chaude",
    usageCold: "Point d'usage eau froide",
    usageBlended: "Point d'usage eau mitigée",
    supplyHot: 'Départ ECS',
    supplyCold: 'Départ EF',
    flowHot: 'Distribution ECS',
    flowCold: 'Distribution EF',
    boiler: 'Chaudière',
    otherHot: 'Autre eau chaude',
    otherCold: 'Autre eau froide',
    none: 'Aucun',
    butterEggCheese: 'Beurre, oeuf, fromage',
    carriageReturn: 'Retour chariot',
    carriageStart: 'Début chariot',
    dataCenter: 'Centre de données',
    defrosting: 'Décongélation',
    doorMain: 'Porte principale',
    doorWc: 'Porte WC',
    finishedProduct: 'Produit fini',
    food: 'Aliment',
    fruitVegetables: 'Fruit, légume',
    meat: 'Viande',
    medicineAmbient: 'Médicament ambiant',
    medicineCold: 'Médicament froid',
    morgue: 'Morgue',
    needClean: 'Besoin de nettoyage',
    needPaper: 'Besoin de papier',
    negative: 'Négatif',
    packaging: 'Emballage',
    preparation: 'Préparation',
    satisfied: 'Satisfait',
    waste: 'Déchet',
    coldPositive: 'Froid positif',
    coldNegative: 'Froid négatif',
    ambient: 'Ambiant',
  },
  deviceConfiguration: "Configuration de l'appareil",
  uncertainty: 'Incertitude',
  tempOffset: 'Décalage de température',
  editDeviceSuccess: 'Configuration du capteur modifiée avec succès',
  editDeviceError: 'Erreur lors de la modification de la configuration du capteur',
  actionValidation: "Validation de l'action",
  reset: 'Réinitialiser',
  sentTo: 'Envoyés à',
  addReportConfiguration: 'Ajouter une configuration',
  editReportConfiguration: 'Modifier une configuration',
  test: 'Tester',
  weekly: 'Hebdomadaire',
  fortnightly: 'Bimensuel',
  monthly: 'Mensuel',
  download: 'Télécharger',
  preview: 'Prévisualiser',
  downloadReportSample: 'Si vous souhaitez tester cette configuration, veuillez sélectionner une plage de dates afin de télécharger une prévisualisation',
  searchBy: 'Rechercher par',
  includeAlerts: 'Inclure le détail des alertes',
  includeDevices: 'Inclure le détail des capteurs (graph)',
  includeActions: 'Inclure le détail des actions',
  usersNotified: 'Utilisateurs notifiés',
  selectUsers: 'Sélectionner les utilisateurs',
  reportAddSuccess: 'Rapport créé avec succès',
  reportAddError: 'Erreur lors de la création du rapport',
  reportConfigurationEditSuccess: 'Configuration du rapport modifiée avec succès',
  reportConfigurationEditError: 'Erreur lors de la modification de la configuration du rapport',
  reportConfigurationAddSuccess: 'Configuration du rapport ajoutée avec succès',
  reportConfigurationAddError: "Erreur lors de l'ajout de la configuration du rapport",
  from: 'Du',
  to: 'au',
  noDateSelected: 'Aucune date sélectionnée',
  reportRemoveWarning: 'Êtes-vous sûr de vouloir supprimer la configuration du rapport',
  deleteReport: 'Suppression de la configuration du rapport',
  reportDeleteSuccess: 'Configuration du rapport supprimée avec succès',
  reportDeleteError: 'Erreur lors de la suppression de la configuration du rapport',
  role: 'Rôle',
  roles: {
    admin: 'Administrateur',
    user: 'Utilisateur',
    manager: 'Manager',
    operator: 'Operateur',
  },
  sites: 'Sites',
  managerAtLeastOneSite: 'Un manager doit être rattaché à au moins un site',
  operatorAtLeastOneSite: 'Un opérateur doit être rattaché à au moins un site',
  equipments: 'Équipements',
  equipmentWithSampling: 'Équipement avec prélèvements',
  equipmentWithAction: 'Équipement avec actions préventives',
  userSitesAssignation: "Assignation de l'utilisateur au(x) site(s)",
  userEquipmentsAssignation: "Assignation de l'utilisateur aux équipements",
  operatorOnlyOneSite: "Un opérateur ne peut être rattaché qu'à un seul site",
  userEditSuccess: 'Utilisateur modifié avec succès',
  userEditError: "Erreur lors de la modification de l'utilisateur",
  userCreateSuccess: 'Utilisateur créé avec succès',
  userCreateError: "Erreur lors de la création de l'utilisateur",
  equipmentsSelected: 'équipement(s) sélectionné(s)',
  passwordComplexityError: 'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial',
  accessForbidden: "Vous n'avez pas accès à cette page.",
  requestAdmin: ' Veuillez vous adresser à votre administrateur.',
  perimeters: 'Périmètres',
  perimetersNone: 'Aucun périmètre',
  perimetersSelected: 'périmètre(s) sélectionné(s)',
  useCase: {
    legionella: 'Carnet sanitaire',
    coldStorage: 'Chaîne du froid',
    energy: 'Énergie',
  },
  noSitesAssigned: "Vous n'êtes rattaché à aucun site.",
  operatorAtLeastOneEquipment: 'Un opérateur doit être rattaché à au moins un équipement',
  nextReportDate: 'Date du prochain rapport',
  deleteUser: 'Suppression de l’utilisateur',
  userDeleteWarning: 'Êtes-vous sûr de vouloir supprimer l’utilisateur',
  userDeleteSuccess: 'Utilisateur supprimé avec succès',
  userDeleteError: 'Erreur lors de la suppression de l’utilisateur',
  formContainsErrors: 'Le formulaire contient des erreurs',
  pleaseSelectTheDevice: 'Préciser le nom du capteur',
  equipmentNoActionWarning: "Vous n'avez pas sélectionné d'actions pour cet équipement",
  equipmentNoDeviceWarning: "Vous n'avez pas sélectionné de capteur pour cet équipement",
  equipmentNoActionDeviceWarning: "Vous n'avez pas sélectionné d'actions ni de capteur pour cet équipement",
  deviceName: 'Nom du capteur',
  reportNameAndFrequency: 'Nom et fréquence du rapport',
  reportContent: 'Contenu du rapport',
  reportEquipments: 'Sélection des équipements',
  noEquipment: 'Aucun équipement',
  type: 'Type',
  eventType: {
    temperature: 'Temperature',
    humidity: 'Humidité',
    waterFlow: 'Vitesse',
    waterMovement: "Mouvement d'eau",
  },
  currentValue: 'Actuelle',
  avg: 'Moyenne',
  min: 'min',
  max: 'max',
  operatorNotAssignedOnEquipmentError: "L'utilisateur {{userMail}} n'est pas assigné à au moins un équipment",
  noNotification: 'Pas de notification',
  viewFullscreen: 'Plein écran',
  printChart: 'Imprimer',
  downloadCSV: 'Télécharger en CSV',
  downloadXLS: 'Télécharger en XLS',
  deleteFileSuccess: 'Fichier supprimé avec succès',
  deleteFileError: 'Erreur lors de la suppression du fichier',
  deleteFile: 'Suppression du fichier',
  deleteFileWarning: 'Êtes-vous sûr de vouloir supprimer le fichier',
  fileType: {
    legioIncident: 'Incident',
    legioSample: 'Prélèvement',
  },
  unassignDevice: 'Désassigner le capteur',
  deviceUnassignedSuccess: 'Capteur désassigné avec succès',
  deviceUnassignedError: 'Erreur lors de la désassignation du capteur',
  loading: 'Chargement',
  period: 'Période',
  equipmentsAll: 'Équipements (tous)',
  fileUploadSuccess: 'Fichier ajouté avec succès',
  fileUploadError: "Erreur lors de l'ajout du fichier",
  noBuilding: 'Aucun bâtiment',
  addBuildingSuccess: 'Bâtiment ajouté avec succès',
  addBuildingError: "Erreur lors de l'ajout du bâtiment",
  deleteBuildingSuccess: 'Bâtiment supprimé avec succès',
  deleteBuildingError: 'Erreur lors de la suppression du bâtiment',
  editBuildingSuccess: 'Bâtiment modifié avec succès',
  editBuildingError: 'Erreur lors de la modification du bâtiment',
  lastComment: 'Dernier commentaire',
  acknowledged: 'Traitée{{suffix}}',
  notAcknowledged: 'Non traitée{{suffix}}',
  severityAll: 'Sévérité (toutes)',
  severitySelected: 'sévérité(s) sélectionnée(s)',
  noDevice: 'Aucun capteur',
  total: 'Total',
  noWaterMovementDuring: "Absence de mouvement d'eau pendant",
  energyConsumptionMonitoring: "Suivi de la consommation d'énergie",
  location: 'Localisation',
  nbBuilding: 'Nb bâtiments',
  nbEquipment: 'Nb équipmenents',
  supervision: 'Supervision',
  allDay: 'Toute la journée',
}

export default fr
