import { DeviceFamily } from '@core/referential/device-family.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'
import { siteDevicesQuery } from '@/queries/site-devices.query'

export const SearchSchema = z.object({
  tab: z.union([z.literal('sensors'), z.literal('gateways')]).default('sensors'),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/site/$id/devices/')({
  validateSearch: SearchSchema,
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.SITE_DEVICES)) {
      throw redirect({
        to: '/403',
      })
    }

    if (isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  loaderDeps({ search }) {
    return search
  },
  loader({ params, context: { queryClient }, deps: { tab } }) {
    if (tab === 'sensors') {
      void queryClient.ensureQueryData(siteDevicesQuery(params.id, { deviceFamily: null }))
    }

    if (tab === 'gateways') {
      void queryClient.ensureQueryData(siteDevicesQuery(params.id, { deviceFamily: DeviceFamily.GATEWAY }))
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
